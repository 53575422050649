var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-h5 pb-6" }, [
        _vm._v(" " + _vm._s(_vm.$t("account.team.teamSettings.header")) + " "),
        _vm.settingsSaveStatus
          ? _c(
              "span",
              {
                staticClass: "font-italic text-subtitle-1",
                class: _vm.settingsSaveStatus.className,
                attrs: { id: "save-status-team-settings" }
              },
              [_vm._v("- " + _vm._s(_vm.$t(_vm.settingsSaveStatus.message)))]
            )
          : _vm._e()
      ]),
      _c("p", { staticClass: "text-body-1 py-0 mb-0" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("account.team.teamSettings.reassess.description")) +
            " "
        )
      ]),
      _c(
        "v-row",
        { staticClass: "pb-8", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-radio-group",
                {
                  attrs: {
                    row: "",
                    id: "reassess-reminder",
                    dense: "",
                    "hide-details": "",
                    disabled: _vm.disabled
                  },
                  on: { change: _vm.updateSettings },
                  model: {
                    value: _vm.reassessReminderSelected,
                    callback: function($$v) {
                      _vm.reassessReminderSelected = $$v
                    },
                    expression: "reassessReminderSelected"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    _vm._l(_vm.reassessReminderOptions, function(item, key) {
                      return _c(
                        "v-col",
                        {
                          key: key,
                          staticClass: "pt-0",
                          attrs: { cols: "12", sm: "6", md: "3", lg: "3" }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t(item.label),
                              value: key,
                              id: "reassess-option-" + key
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0", attrs: { cols: "12", sm: "10" } },
                    [
                      _vm.reassessReminderSelected !== null
                        ? _c(
                            "span",
                            { staticClass: "text-body-1 vitrueGrey--text" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    _vm.reassessReminderOptions[
                                      _vm.reassessReminderSelected
                                    ].footer
                                  )
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-6", attrs: { align: "end", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "mr-4 text-body-1", attrs: { cols: "auto" } },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "account.team.teamSettings.domainNameSettings.toggle.text"
                  )
                )
              )
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-switch", {
                attrs: {
                  id: "shareableLinkToggle",
                  "hide-details": "",
                  color: "secondary",
                  disabled: _vm.disabled
                },
                on: {
                  change: function($event) {
                    return _vm.updateSettings()
                  }
                },
                model: {
                  value: _vm.allowShareableLink,
                  callback: function($$v) {
                    _vm.allowShareableLink = $$v
                  },
                  expression: "allowShareableLink"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mt-1 vitrueGrey--text", attrs: { cols: "12" } },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "account.team.teamSettings.domainNameSettings.toggle.description"
                  )
                )
              )
            ]
          )
        ],
        1
      ),
      _c("p", { staticClass: "text-body-1" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "account.team.teamSettings.domainNameSettings.extraDomains.title"
              )
            ) +
            " "
        )
      ]),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", xl: "4" } },
            [
              _c("DomainNameInputField", {
                staticClass: "mb-2",
                attrs: { loading: _vm.loadingDomains, disabled: _vm.disabled },
                on: { update: _vm.updateSettings },
                model: {
                  value: _vm.domainNames,
                  callback: function($$v) {
                    _vm.domainNames = $$v
                  },
                  expression: "domainNames"
                }
              }),
              _c("span", { staticClass: "mt-2 vitrueGrey--text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "account.team.teamSettings.domainNameSettings.extraDomains.description"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-treeview", {
            attrs: {
              items: _vm.provisioningItems,
              disabled: !_vm.provisioningToken
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.title
                      ? _c(
                          "div",
                          { staticClass: "primary--text text-caption" },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "p",
                              { staticClass: "mb-1" },
                              [
                                _vm._v(" " + _vm._s(item.name) + " "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      right: _vm.$vuetify.breakpoint.mdAndUp,
                                      bottom: !_vm.$vuetify.breakpoint.mdAndUp
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "blue",
                                                        small: ""
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.description))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex tokenContainer" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.copyToClipboard(item.value)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "account.team.teamSettings.provisioning.copy"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "mb-0 mx-2 tokenText" },
                                  [_vm._v(" " + _vm._s(item.value) + " ")]
                                )
                              ],
                              1
                            ),
                            _c("v-slide-y-transition", [
                              _vm.showCopySuccess
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-0 ml-3 mt-1 green--text copySuccessMessage"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "account.team.teamSettings.provisioning.copySuccess"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }