var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    ref: "comboBox",
    attrs: {
      id: "comboBox",
      multiple: "",
      disabled: _vm.loading || _vm.disabled,
      loading: _vm.loading,
      label: _vm.$t(
        "account.team.teamSettings.domainNameSettings.extraDomains.label"
      ),
      chips: "",
      filled: "",
      dense: "",
      "hide-no-data": "",
      "hide-details": _vm.noInvalidDomains,
      messages: _vm.$t(
        "account.team.teamSettings.domainNameSettings.extraDomains.error"
      )
    },
    on: {
      keydown: _vm.processKeyDown,
      "update:search-input": _vm.processDomains
    },
    scopedSlots: _vm._u([
      {
        key: "append",
        fn: function() {
          return [
            _c("div", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { id: "hideDropDownDiv" }
            })
          ]
        },
        proxy: true
      },
      {
        key: "message",
        fn: function(ref) {
          var key = ref.key
          var message = ref.message
          return [
            _c("p", { staticClass: "brightError--text text-subtitle-1" }, [
              _vm._v(_vm._s(message))
            ])
          ]
        }
      },
      {
        key: "selection",
        fn: function(ref) {
          var attrs = ref.attrs
          var item = ref.item
          var parent = ref.parent
          var selected = ref.selected
          return [
            _c(
              "v-chip",
              _vm._b(
                {
                  key: JSON.stringify(item),
                  attrs: {
                    id: item.domainName + "Chip",
                    "input-value": selected,
                    color: _vm.validDomain(item)
                      ? "selectedPrimary"
                      : "brightError",
                    "text-color": _vm.validDomain(item) ? "primary" : "white",
                    close: "",
                    small: "",
                    ripple: ""
                  },
                  on: {
                    "click:close": function($event) {
                      return _vm.removeDomain(item)
                    }
                  }
                },
                "v-chip",
                attrs,
                false
              ),
              [
                _vm._v(
                  " " + _vm._s(item.domainName ? item.domainName : item) + " "
                )
              ]
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.comboBoxModel,
      callback: function($$v) {
        _vm.comboBoxModel = $$v
      },
      expression: "comboBoxModel"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }