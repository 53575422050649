<template>
  <div>
    <p class="text-h5 pb-6">
      {{ $t("account.team.teamSettings.header") }}
      <span
        id="save-status-team-settings"
        class="font-italic text-subtitle-1"
        v-if="settingsSaveStatus"
        :class="settingsSaveStatus.className"
        >- {{ $t(settingsSaveStatus.message) }}</span
      >
    </p>
    <p class="text-body-1 py-0 mb-0">
      {{ $t("account.team.teamSettings.reassess.description") }}
    </p>
    <v-row no-gutters class="pb-8">
      <v-col>
        <v-radio-group
          row
          v-model="reassessReminderSelected"
          v-on:change="updateSettings"
          id="reassess-reminder"
          dense
          hide-details
          :disabled="disabled"
        >
          <v-row align="center">
            <v-col
              class="pt-0"
              cols="12"
              sm="6"
              md="3"
              lg="3"
              v-for="(item, key) in reassessReminderOptions"
              v-bind:key="key"
            >
              <v-radio
                :label="$t(item.label)"
                :value="key"
                :id="`reassess-option-${key}`"
              ></v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
        <v-row class="mt-2">
          <v-col cols="12" sm="10" class="pt-0">
            <span
              v-if="reassessReminderSelected !== null"
              class="text-body-1 vitrueGrey--text"
              >{{
                $t(reassessReminderOptions[reassessReminderSelected].footer)
              }}</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="end" no-gutters class="mb-6"
      ><v-col cols="auto" class="mr-4 text-body-1">{{
        $t("account.team.teamSettings.domainNameSettings.toggle.text")
      }}</v-col>
      <v-col cols="auto"
        ><v-switch
          id="shareableLinkToggle"
          v-model="allowShareableLink"
          hide-details
          color="secondary"
          :disabled="disabled"
          @change="updateSettings()"
        >
        </v-switch
      ></v-col>
      <v-col cols="12" class="mt-1 vitrueGrey--text">{{
        $t("account.team.teamSettings.domainNameSettings.toggle.description")
      }}</v-col>
    </v-row>
    <p class="text-body-1">
      {{
        $t("account.team.teamSettings.domainNameSettings.extraDomains.title")
      }}
    </p>
    <v-row no-gutters>
      <v-col cols="12" md="6" xl="4">
        <DomainNameInputField
          class="mb-2"
          v-model="domainNames"
          :loading="loadingDomains"
          @update="updateSettings"
          :disabled="disabled"
        />
        <span class="mt-2 vitrueGrey--text">
          {{
            $t(
              "account.team.teamSettings.domainNameSettings.extraDomains.description"
            )
          }}
        </span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-treeview :items="provisioningItems" :disabled="!provisioningToken">
        <template v-slot:label="{ item }">
          <div v-if="item.title" class="primary--text text-caption">
            {{ item.title }}
          </div>
          <div v-else>
            <p class="mb-1">
              {{ item.name }}
              <v-tooltip
                :right="$vuetify.breakpoint.mdAndUp"
                :bottom="!$vuetify.breakpoint.mdAndUp"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" color="blue" small
                    >mdi-information</v-icon
                  >
                </template>
                <span>{{ item.description }}</span>
              </v-tooltip>
            </p>
            <div class="d-flex tokenContainer">
              <v-btn color="primary" @click="copyToClipboard(item.value)">{{
                $t("account.team.teamSettings.provisioning.copy")
              }}</v-btn>
              <p class="mb-0 mx-2 tokenText">
                {{ item.value }}
              </p>
            </div>
            <v-slide-y-transition>
              <p
                v-if="showCopySuccess"
                class="mb-0 ml-3 mt-1 green--text copySuccessMessage"
              >
                {{ $t("account.team.teamSettings.provisioning.copySuccess") }}
              </p>
            </v-slide-y-transition>
          </div>
        </template>
      </v-treeview>
    </v-row>
  </div>
</template>

<script>
import { getTeamSettings, updateTeamSettings } from "../../customApi";
import { findIndex, clone } from "lodash";
import DomainNameInputField from "@/components/account/DomainNameInputField";

export default {
  name: "TeamSettings",
  components: { DomainNameInputField },
  props: {
    disabled: Boolean
  },
  data() {
    return {
      teamSettings: null,
      domainNames: [],
      loadingDomains: true,
      reassessReminderSelected: null,
      allowShareableLink: false,
      provisioningToken: "",
      reassessReminderOptions: [
        {
          value: 90,
          label:
            "account.team.teamSettings.reassess.reassessReminderOptions[0].label",
          footer:
            "account.team.teamSettings.reassess.reassessReminderOptions[0].footer"
        },
        {
          value: 180,
          label:
            "account.team.teamSettings.reassess.reassessReminderOptions[1].label",
          footer:
            "account.team.teamSettings.reassess.reassessReminderOptions[1].footer"
        },
        {
          value: 360,
          label:
            "account.team.teamSettings.reassess.reassessReminderOptions[2].label",
          footer:
            "account.team.teamSettings.reassess.reassessReminderOptions[2].footer"
        },
        {
          value: null,
          label:
            "account.team.teamSettings.reassess.reassessReminderOptions[3].label",
          footer:
            "account.team.teamSettings.reassess.reassessReminderOptions[3].footer"
        }
      ],
      saveStates: {
        none: null,
        saving: {
          message: "account.team.saveStates.saving",
          className: "subtleSuccess"
        },
        saved: {
          message: "account.team.saveStates.saved",
          className: "subtleSuccess"
        },
        failed: {
          message: "account.team.saveStates.failed",
          className: "subtleFailure"
        }
      },
      settingsSaveStatus: null,
      statusTimeouts: [],
      showCopySuccess: false
    };
  },
  async mounted() {
    this.loadingDomains = true;
    try {
      this.teamSettings = await getTeamSettings();
      this.domainNames = [...this.teamSettings.domainNames];
      this.allowShareableLink = this.teamSettings.allowShareableLink;
      this.provisioningToken = this.teamSettings.apiToken;
      this.setSelectedReassessReminder();
    } catch (error) {
      this.$logger.captureException(error);
    }
  },
  computed: {
    provisioningItems() {
      return [
        {
          id: 1,
          title: this.$t("account.team.teamSettings.advancedSettings"),
          children: [
            {
              id: 2,
              name: this.$t("account.team.teamSettings.provisioning.title"),
              description: this.$t(
                "account.team.teamSettings.provisioning.description"
              ),
              value: this.provisioningToken
            }
          ]
        }
      ];
    }
  },
  methods: {
    updateSettings() {
      let fallback = clone(this.teamSettings);

      this.statusTimeouts.forEach(timeout => clearTimeout(timeout));
      this.statusTimeouts = [];

      this.settingsSaveStatus = this.saveStates.saving;
      this.teamSettings.deskAssessmentReminderInterval =
        this.reassessReminderOptions[this.reassessReminderSelected].value;

      this.teamSettings.domainNames = [...this.domainNames];
      this.teamSettings.allowShareableLink = this.allowShareableLink;

      let vm = this;
      updateTeamSettings(this.teamSettings)
        .then(response => {
          vm.teamSettings = response;
          vm.setSelectedReassessReminder();

          vm.statusTimeouts.push(
            setTimeout(() => (vm.settingsSaveStatus = vm.saveStates.saved), 500)
          );
          vm.statusTimeouts.push(
            setTimeout(() => (vm.settingsSaveStatus = vm.saveStates.none), 3000)
          );
        })
        .catch(err => {
          vm.statusTimeouts.forEach(timeout => clearTimeout(timeout));
          vm.statusTimeouts = [];

          vm.$logger.captureException(err);

          vm.settingsSaveStatus = vm.saveStates.failed;
          vm.teamSettings = clone(fallback);

          vm.statusTimeouts.push(
            setTimeout(() => (vm.settingsSaveStatus = vm.saveStates.none), 3000)
          );
          vm.statusTimeouts.push(
            setTimeout(() => vm.setSelectedReassessReminder(), 1000)
          );
        });
    },
    setSelectedReassessReminder() {
      let vm = this;
      this.reassessReminderSelected = findIndex(
        this.reassessReminderOptions,
        o => o.value === vm.teamSettings.deskAssessmentReminderInterval
      );
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.showCopySuccess = true;
      setTimeout(() => (this.showCopySuccess = false), 2000);
    }
  },
  watch: {
    domainNames() {
      if (this.loadingDomains) {
        this.loadingDomains = false;
        return;
      }

      this.updateSettings();
    }
  }
};
</script>

<style lang="scss" scoped>
.subtleSuccess {
  color: var(--v-success-base);
}
.subtleFailure {
  color: var(--v-error-base);
}

.tokenText {
  width: 40vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  line-height: 36px;
}

.tokenContainer {
  border: 1px solid var(--v-disabled-base);
  border-radius: 5px;
}

.copySuccessMessage {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}
</style>
