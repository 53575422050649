<template>
  <v-combobox
    ref="comboBox"
    id="comboBox"
    v-model="comboBoxModel"
    @keydown="processKeyDown"
    @update:search-input="processDomains"
    multiple
    :disabled="loading || disabled"
    :loading="loading"
    :label="
      $t('account.team.teamSettings.domainNameSettings.extraDomains.label')
    "
    chips
    filled
    dense
    hide-no-data
    :hide-details="noInvalidDomains"
    :messages="
      $t('account.team.teamSettings.domainNameSettings.extraDomains.error')
    "
  >
    <template v-slot:append>
      <div id="hideDropDownDiv" style="width: 100%; height: 100%"></div
    ></template>

    <template v-slot:message="{ key, message }">
      <p class="brightError--text text-subtitle-1">{{ message }}</p></template
    >
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        :key="JSON.stringify(item)"
        :id="item.domainName + 'Chip'"
        v-bind="attrs"
        :input-value="selected"
        :color="validDomain(item) ? 'selectedPrimary' : 'brightError'"
        :text-color="validDomain(item) ? 'primary' : 'white'"
        close
        small
        ripple
        @click:close="removeDomain(item)"
      >
        {{ item.domainName ? item.domainName : item }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { isSubdomainValid } from "@/customApi.js";
export default {
  name: "DomainNameInputField",
  props: {
    value: Array,
    loading: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      invalidDomains: []
    };
  },
  methods: {
    validDomain(domain) {
      return !this.invalidDomains.includes(domain);
    },
    processKeyDown(e) {
      if (e.key === "Backspace" && !this.$refs.comboBox.internalSearch) {
        if (this.invalidDomains.length > 0) {
          this.invalidDomains.pop();
          return;
        }
        this.value.pop();
        this.updateValue(this.value);
      }
    },
    removeDomain(item) {
      let tempArray = [...this.value];
      var itemIndexInValueArray = tempArray.indexOf(item);
      if (itemIndexInValueArray !== -1) {
        tempArray.splice(itemIndexInValueArray, 1);
        this.updateValue(tempArray);
      }

      var itemIndexInInvalidArray = this.invalidDomains.indexOf(item);
      if (itemIndexInInvalidArray !== -1) {
        this.invalidDomains.splice(itemIndexInInvalidArray, 1);
      }
    },
    async processDomains(input) {
      let comboBoxValue = input?.testVal ?? input;
      if (!comboBoxValue) {
        return;
      }

      const inverseOfAllowedCharacters = /[^a-z0-9@\-_.']+/gi;
      const includesInvalidCharacter = comboBoxValue.match(
        inverseOfAllowedCharacters
      );

      // if no invalid characters - then user hasn't entered something to require checking for new domain
      if (!includesInvalidCharacter) {
        return;
      }

      const newDomainNames = comboBoxValue
        .split(inverseOfAllowedCharacters)
        .filter(str => !!str);
      const convertedDomainNames = this.convertNamesToDtos(newDomainNames);

      const updatedArray = await this.getValidDomainsAndDiscardInvalidNames(
        convertedDomainNames
      );

      this.$refs.comboBox.internalSearch = "";
      this.updateValue(updatedArray);
    },
    async getValidDomainsAndDiscardInvalidNames(updatedDtoArray) {
      var arrayToEmit = [...this.value];
      for (var i = 0; i < updatedDtoArray.length; i++) {
        var domain = updatedDtoArray[i];
        var valid = await isSubdomainValid(domain.domainName);
        var domainExists =
          this.domainNameExists(domain.domainName) ||
          arrayToEmit.some(x => x.domainName === domain.domainName);
        if (domainExists) {
          continue;
        }
        if (valid) {
          arrayToEmit.push(domain);
          continue;
        }
        this.invalidDomains.push(domain);
      }
      return arrayToEmit;
    },
    convertNamesToDtos(array) {
      let convertedArray = array.map(x => {
        if (x.domainName) {
          return x;
        }
        return { domainName: x };
      });
      return convertedArray;
    },
    domainNameExists(domainName) {
      return (
        this.value.some(x => x.domainName === domainName) ||
        this.invalidDomains.some(x => x.domainName === domainName)
      );
    },
    updateValue(newArray) {
      let arraysEqual =
        newArray.length === this.value.length &&
        newArray.every((value, index) => value === this.value[index]);

      if (arraysEqual) {
        return;
      }
      this.$emit("input", newArray);
    }
  },
  computed: {
    // required for v-model in combo box
    // returning the actual v-model values and any invalid domains
    comboBoxModel: {
      get() {
        return this.value.concat(this.invalidDomains);
      },
      async set(newArray) {
        let convertedArray = this.convertNamesToDtos(newArray);
        let arrayToEmit = await this.getValidDomainsAndDiscardInvalidNames(
          convertedArray
        );
        this.updateValue(arrayToEmit);
      }
    },
    noInvalidDomains() {
      return this.invalidDomains.length === 0;
    }
  }
};
</script>
